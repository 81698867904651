<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      inline
      icon="mdi-account"
      class="ml-4 px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-1 font-weight-light">Informações Pessoais</div>
      </template>
      <perfil-usuario-detalhes
        :userInfo="userInfo"
        :loading="loading"
      />
    </base-material-card>
    <base-material-card
      color="primary"
      inline
      icon="mdi-office-building"
      class="ml-4 px-5 pt-3 pb-8"
    >
      <template v-slot:after-heading>
        <div class="display-1 font-weight-light">
          Lista de Empresas Liberadas
        </div>
      </template>
      <v-row class="mx-2">
        <v-col cols="12">
          <empresas-liberadas-usuario-data-table
            :acessos="userInfo.acessos"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import EmpresasLiberadasUsuarioDataTable from '@/components/usuarios/EmpresasLiberadasUsuarioDataTable.vue';
import PerfilUsuarioDetalhes from '@/components/usuarios/PerfilUsuarioDetalhes.vue';

export default {
  name: 'VisualizarPerfil',
  components: {
    EmpresasLiberadasUsuarioDataTable,
    PerfilUsuarioDetalhes
  },
  data: () => ({
    userInfo: {
      login: '',
      nome: '',
      perfis: [],
      email: '',
      tipo: null,
      cargo: null,
      diretoria: null,
      area_executiva: null,
      acessos: []
    },
    loading: false
  }),
  mounted() {
    this.getInfoUser();
  },
  methods: {
    getInfoUser() {
      this.loading = true;
      AuthService.getUserInfoById(this.$route.params.id)
        .then((responseData) => (this.userInfo = responseData))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
